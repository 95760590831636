import { useContext, useState } from "react";
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Button, Divider, Grid, TextField } from "@mui/material";
import { HeaderText } from "../common/headerText";
import { MenuText } from "../common/menuText";
import { AltitudeContext } from "../mainView/altitudeContext";
import Close from '../images/close/close.svg';
import CloseLight from '../images/close/close-light.svg';


export function LockMenu({ onClose, onLock }: { onClose: () => void, onLock: () => void }) {
  const { deviceShadow, setDeviceShadow, publishDeviceShadow } = useContext(AltitudeContext);
  const [isEditingSettingsPin, setIsEditingSettingsPin] = useState(false);
  const [isEditingSystemPin, setIsEditingSystemPin] = useState(false);
  const [settingsPin, setSettingsPin] = useState(deviceShadow.lock_settings_pin);
  const [systemPin, setSystemPin] = useState(deviceShadow.lock_system_pin);
  const [attemptUnlock, setAttemptUnlock] = useState(false);
  const [attemptPin, setAttemptPin] = useState("");

  const onSaveSettingsPin = () => {
    const newShadow = {
      ...deviceShadow,
      lock_settings_pin: settingsPin
    };
    setDeviceShadow(newShadow);
    setIsEditingSettingsPin(false);
    publishDeviceShadow(newShadow);
  };

  const onClearSettingsPin = () => {
    setSettingsPin(deviceShadow.lock_settings_pin);
    setIsEditingSettingsPin(false);
  };

  const onRemoveSettingsPin = () => {
    const newShadow = {
      ...deviceShadow,
      lock_settings_pin: null
    };
    setSettingsPin(null);
    setDeviceShadow(newShadow);
    publishDeviceShadow(newShadow);
  };

  const lockSettings = () => {
    const newShadow = {
      ...deviceShadow,
      lock_settings: true,
    };
    setDeviceShadow(newShadow);
    publishDeviceShadow(newShadow);
    onLock();
  };

  const onSaveSystemPin = () => {
    const newShadow = {
      ...deviceShadow,
      lock_system_pin: systemPin,
    };
    setDeviceShadow(newShadow);
    setIsEditingSystemPin(false);
    publishDeviceShadow(newShadow);
  };

  const onClearSystemPin = () => {
    setSystemPin(deviceShadow.lock_system_pin);
    setIsEditingSystemPin(false);
  };

  const onRemoveSystemPin = () => {
    const newShadow = {
      ...deviceShadow,
      lock_system_pin: null
    };
    setSystemPin(null);
    setDeviceShadow(newShadow);
    publishDeviceShadow(newShadow);
  };

  const lockSystem = () => {
    const newShadow = {
      ...deviceShadow,
      lock_system: true,
    };
    setDeviceShadow(newShadow);
    publishDeviceShadow(newShadow);
  };

  const onUnlockClicked = () => {
    setAttemptUnlock(true);
  };


  const unlockSystem = () => {
    if (attemptPin === deviceShadow.lock_system_pin) {
      const newShadow = {
        ...deviceShadow,
        lock_system: false,
      };
      setDeviceShadow(newShadow);
      publishDeviceShadow(newShadow);
      setAttemptUnlock(false);
    } else {
      setAttemptUnlock(false);
    }
  }

  return (
    <Drawer
      open
      anchor="right"
      onClose={onClose}
      sx={{ "& .MuiPaper-root": { width: "100%", padding: "38px 32px" } }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton onClick={onClose}>
          <ChevronLeftIcon sx={{ fontSize: '50px' }} />
        </IconButton>
        <HeaderText>Lock menu</HeaderText>
      </Stack>
      <Divider />

      {
        attemptUnlock ?
          <Drawer
            open
            anchor="right"
            onClose={onClose}
            sx={{ "& .MuiPaper-root": { width: "100%", padding: '38px 32px' } }}
          >
            <Grid container>
              <Grid item xs={6}>
                <HeaderText>Enter System Lock Pin</HeaderText>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Button onClick={() => setAttemptUnlock(false)}>
                  <img src={deviceShadow.appearance === 'LIGHT' ? Close : CloseLight} alt='Close' />
                </Button>
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="space-evenly" sx={{ mt: "2rem" }}>
              <TextField type="number" label="Enter Pin" value={attemptPin} onChange={(e) => setAttemptPin(e.target.value)} />
              <Button
                variant="contained"
                color="primary"
                sx={{ fontWeight: 700 }}
                onClick={unlockSystem}
              >
                Accept
              </Button>
            </Stack>
          </Drawer> :
          <>
            <Stack sx={{ mt: "2rem" }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: "75%" }}>
                <MenuText>System Lock</MenuText>
                <Stack direction="row" sx={{ ml: "auto", width: "75%" }} justifyContent="space-evenly">
                  {
                    isEditingSystemPin ?
                      <>
                        <TextField
                          value={systemPin || ''}
                          label="System Pin"
                          type="number"
                          onChange={(e) => setSystemPin(e.target.value)}
                        >
                        </TextField>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ fontWeight: 700 }}
                          onClick={onSaveSystemPin}
                        >
                          Set
                        </Button>
                        <Button
                          variant="contained"
                          color="warning"
                          sx={{ fontWeight: 700 }}
                          onClick={onClearSystemPin}
                        >
                          Cancel
                        </Button>
                      </> :
                      deviceShadow.lock_system ?
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ fontWeight: 700 }}
                          onClick={onUnlockClicked}
                        >
                          Unlock
                        </Button> :
                        <>
                          {systemPin ?
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ fontWeight: 700 }}
                                onClick={onRemoveSystemPin}
                              >
                                Remove Pin
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ fontWeight: 700 }}
                                onClick={lockSystem}
                              >
                                Lock
                              </Button>
                            </> :
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ fontWeight: 700 }}
                              onClick={() => setIsEditingSystemPin(true)}
                            >
                              Set Pin
                            </Button>

                          }
                        </>
                  }
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={{ mt: "2rem" }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: "75%" }}>
                <MenuText>Settings Lock</MenuText>
                <Stack direction="row" sx={{ ml: "auto", width: "75%" }} justifyContent="space-evenly">
                  {
                    isEditingSettingsPin ?
                      <>
                        <TextField
                          value={settingsPin || ''}
                          label="Settings Pin"
                          type="number"
                          onChange={(e) => setSettingsPin(e.target.value)}
                        >
                        </TextField>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ fontWeight: 700 }}
                          onClick={onSaveSettingsPin}
                        >
                          Set
                        </Button>
                        <Button
                          variant="contained"
                          color="warning"
                          sx={{ fontWeight: 700 }}
                          onClick={onClearSettingsPin}
                        >
                          Cancel
                        </Button>
                      </> :
                      <>
                        {
                          settingsPin ?
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ fontWeight: 700 }}
                                onClick={onRemoveSettingsPin}
                              >
                                Remove Pin
                              </Button>

                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ fontWeight: 700 }}
                                onClick={lockSettings}
                              >
                                Lock
                              </Button>
                            </> :
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ fontWeight: 700 }}
                              onClick={() => setIsEditingSettingsPin(true)}
                            >
                              Set Pin
                            </Button>

                        }
                      </>
                  }
                </Stack>
              </Stack>
            </Stack>
          </>
      }
    </Drawer >
  );
}
