import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { AltitudeDisplay } from "./altitudeDisplay";
import { SettingsMenu } from "../settingsMenu";
import HelpIcon from '../images/help/help-icon.svg';
import HelpIconLight from '../images/help/help-icon-light.svg';
import LogoDark from "../images/logo-dark.png";
import LogoLight from "../images/logo.png";
import SettingsIcon from '../images/settings/settings-icon.svg';
import SettingsIconLight from '../images/settings/settings-icon-light.svg';
import LockIcon from '../images/lock/nav-lock.svg';
import LockIconLight from '../images/lock/nav-lock-light.svg';
import RetryLight from '../images/retry/retry-light.svg';
import RetryDark from '../images/retry/retry-dark.svg';
import { SettingsContext } from "../settingsContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles/createTheme";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { MainViewPortraitLayout } from "./portrait";
import { MainViewLandscapeLayout } from "./landscape";
import { ViewType } from "./viewType";
import { OffView } from "./offView";
import { HelpOverlay } from "./helpOverlay";
import { Box } from "@mui/system";
import { AltitudeContext } from "./altitudeContext";
import { StandbyDisplay } from "./standbyDisplay";
import Skeleton from "react-loading-skeleton";
import HelpOverlayImg from "../images/help/help-overlay.png";

import 'react-loading-skeleton/dist/skeleton.css';


const LoadingState = () => (
  <Box sx={
    {
      width: "100%",
      paddingTop: "1rem",
    }
  }>
    <Skeleton count={8} height="25px" highlightColor="#1D3444" />
  </Box >
);

// flex settings are needed for the sidebar to not cover up other content
export function MainView() {
  const { deviceShadow, setDeviceShadow, publishDeviceShadow } = useContext(AltitudeContext);

  const [settingsMenuIsOpen, setSettingsMenuIsOpen] = useState(false);
  const isPortraitMode = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [showHelpOverlay, setShowHelpOverlay] = useState(false);

  const setAndPublishDeviceMode = (mode: ViewType) => {
    const newShadow = {
      ...deviceShadow,
      current_machine_state: mode
    };
    setDeviceShadow(newShadow);
    publishDeviceShadow(newShadow);
  };

  return (
    <>
      {!isPortraitMode && (
        <MainViewLandscapeLayout setActiveView={setAndPublishDeviceMode}>
          <MainViewInner
            setShowHelpOverlay={setShowHelpOverlay}
            setSettingsMenuIsOpen={setSettingsMenuIsOpen}
            activeView={deviceShadow.current_machine_state}
            setActiveView={setAndPublishDeviceMode}
          />
        </MainViewLandscapeLayout>
      )}
      {isPortraitMode && (
        <MainViewPortraitLayout setActiveView={setAndPublishDeviceMode}>
          <MainViewInner
            setShowHelpOverlay={setShowHelpOverlay}
            setSettingsMenuIsOpen={setSettingsMenuIsOpen}
            activeView={deviceShadow.current_machine_state}
            setActiveView={setAndPublishDeviceMode}
          />
        </MainViewPortraitLayout>
      )}
      {settingsMenuIsOpen && (
        <SettingsMenu onClose={() => setSettingsMenuIsOpen(false)} />
      )}
      <HelpOverlay img={HelpOverlayImg} open={showHelpOverlay} onClick={() => setShowHelpOverlay(false)} />
    </>
  );
}

function MainViewInner({
  setSettingsMenuIsOpen,
  activeView,
  setActiveView,
  setShowHelpOverlay,
}: {
  setSettingsMenuIsOpen: (isOpen: boolean) => void;
  activeView: ViewType;
  setActiveView: (view: ViewType) => void;
  setShowHelpOverlay: (show: boolean) => void;
}) {
  const { deviceShadow, deviceReadingErrored, initialDeviceReadingDone, setInitialDeviceReadingDone,
    setDeviceReadingErrored, shadowGetResponded } = useContext(AltitudeContext);
  const { availableThings, iotTopic, setIotTopic, isAdmin, thingsRetrieved, setThingsRetrieved } = useContext(SettingsContext);

  const onThingSelected = (evt: React.ChangeEvent | SelectChangeEvent<unknown>, value: string) => {
    setIotTopic(value);
  }

  const isEqual = (option: any, value: any) => {
    return option.thingName === value.thingName;
  }

  const getDeviceSelect = () => {
    if (availableThings.length < 1) return null;
    if (isAdmin) {
      return (
        <Autocomplete
          disableClearable
          sx={{ minWidth: '275px' }}
          options={availableThings}
          onChange={onThingSelected as any}
          value={iotTopic}
          getOptionLabel={(thing) => thing.attributes?.controller_name || thing.thingName}
          ListboxProps={{ sx: { maxHeight: '80px' } }}
          renderInput={(params) => {
            return <TextField {...params} label="Device" />
          }}
          isOptionEqualToValue={isEqual}
        />)
    } else {
      return (
        <FormControl fullWidth sx={{ minWidth: '275px' }}>
          <InputLabel id="deviceSelect">Device</InputLabel>
          <Select labelId="deviceSelect" label="Device" onChange={(e) => {
            const thing = availableThings.find((thing) => thing.thingName === e.target.value);
            setIotTopic(thing);
          }} value={iotTopic?.thingName || ''}>
            {
              availableThings.map((at) => {
                return (
                  <MenuItem key={at.thingName} value={at.thingName}>{at.attributes?.controller_name || at.thingName}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      )
    }
  };
  const isLightMode = deviceShadow.appearance === 'LIGHT';
  return (
    <Stack sx={{ 'width': '100%' }}>
      <Stack direction='row' sx={{ alignItems: 'center' }}>
        <img
          style={{ 'marginRight': 'auto' }}
          src={isLightMode ? LogoLight : LogoDark}
          alt="Altitude Control Technology"
        />
        <Stack direction="row">
          {getDeviceSelect()}
          <Button onClick={() => setShowHelpOverlay(true)}>
            <img src={isLightMode ? HelpIcon : HelpIconLight} alt="Help" height="32px" width="32px" />
          </Button>
          {
            !deviceReadingErrored &&
              deviceShadow.lock_settings ?
              <Button onClick={() => setSettingsMenuIsOpen(true)}>
                <img src={isLightMode ? LockIcon : LockIconLight} alt='Locked' height="32px" width="32px" />
              </Button> :
              <Button onClick={() => setSettingsMenuIsOpen(true)}>
                <img src={isLightMode ? SettingsIcon : SettingsIconLight} alt='Settings' height="32px" width="32px" />
              </Button>
          }
        </Stack>
      </Stack>
      <Divider />
      {
        thingsRetrieved || initialDeviceReadingDone || shadowGetResponded ?
          <>
            {
              deviceReadingErrored || availableThings.length < 1 ?
                <Box sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '1rem',
                  fontSize: '1.5rem',
                }}>
                  <Box>
                    <Box sx={{ mb: '1rem', textAlign: 'center' }}>
                      <Button onClick={() => {
                        setDeviceReadingErrored(false);
                        setInitialDeviceReadingDone(false);
                        setThingsRetrieved(false);
                      }}>
                        <img src={deviceShadow.appearance === "LIGHT" ? RetryDark : RetryLight} alt='Locked' height="32px" width="32px" />
                        <Box sx={{ ml: '1rem' }}>TAP HERE TO RETRY CONNECTION</Box>
                      </Button>
                    </Box>
                    {

                      availableThings.length > 1 ?
                        'This device is currently unavailable, please make sure it has an active Internet connection. If you have access to multiple devices, please select another one from the drop-down above. If you need further assistance please contact Service@AltitudeControl.com.'
                        :
                        'This device is currently unavailable, please make sure it has an active Internet connection. If you need further assistance please contact Service@AltitudeControl.com.'
                    }
                  </Box>
                </Box>
                :
                <>
                  {/* should be routed but started this way so keep it this way til 1.2 */}
                  {activeView === "RUN" && <AltitudeDisplay />}
                  {activeView === "OFF" && (
                    <OffView activateRunMode={() => setActiveView("RUN")} />
                  )}
                  {activeView === 'STANDBY' && <StandbyDisplay />}
                </>
            }

          </> :
          <LoadingState />
      }
    </Stack>
  );
}
