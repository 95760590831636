import { PaletteMode, Theme } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles";
import createTheme from "@mui/material/styles/createTheme";
import { getPalette } from "./getPalette";

const spacingFactor = 16;

const baseThemeOptions = {
  spacing: spacingFactor,
  typography: {
    fontFamily: ["Nunito Sans", "sans-serif"].join(","), //https://fonts.google.com/specimen/Nunito+Sans?query=nunito#standard-styles
  },
} as ThemeOptions;

function getThemeWithComponentOverrides(baseTheme: Theme) {
  return createTheme(baseTheme, {
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: "contained" },
            style: {
              borderRadius: 20,
              boxShadow: "none",
              padding: baseTheme.spacing(0.5, 2),
            },
          },
        ],
      },
    },
  });
}

export function getBaseTheme(colorMode: PaletteMode) {
  const baseTheme = createTheme({
    ...getPalette(colorMode),
    ...baseThemeOptions,
  });
  const finalTheme = getThemeWithComponentOverrides(baseTheme);
  return finalTheme;
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    sidebar: true;
  }
}
