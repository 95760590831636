import styled from "@mui/material/styles/styled";


export const HeaderText: any = styled("header")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "24.15px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "24.15px",
  },
  fontWeight: 700,
  textTransform: "uppercase",
  lineHeight: 1,
}));
