import { PaletteMode } from "@mui/material";

// details about how dark mode in material ui works: https://mui.com/customization/dark-mode/
// details about palette: https://mui.com/customization/palette/
// details about palette options: https://mui.com/customization/default-theme/?expand-path=$.palette
export const getPalette = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          background: {
            paper: "#EAEAEA",
            default: "#EAEAEA",
          },
          text: {
            primary: "#1D3444",
          },
          primary: {
            main: "#1D3444", //"#88949C",
          },
        }
      : {
          // palette values for dark mode
          background: {
            paper: "#272727",
            default: "#272727",
          },
          text: {
            primary: "#9EA8AE",
          },
          primary: {
            main: "#696969",
          },
        }),
  },
});
