import Button from "@mui/material/Button";
import RunImage from "../images/run/mode-run.png";
import RunImageDark from "../images/run/mode-run-dark.png";
import OffModeImage from "../images/off/mode-off.png";
import OffModeImageDark from "../images/off/mode-off-dark.png";
import StandbyImage from "../images/standby/mode-standby.png";
import StandbyImageDark from "../images/standby/mode-standby-dark.png";
import { useContext } from "react";
import { Stack } from "@mui/material";
import { ViewType } from "./viewType";
import { AltitudeContext } from "./altitudeContext";

export function SideBarContent({ setActiveView }: {
  setActiveView: (view: ViewType) => void;
}) {
  const { deviceShadow, initialDeviceReadingDone } = useContext(AltitudeContext);
  return (
    <Stack sx={{ backgroundColor: '#1D3444', height: '100%' }}>
      <SidebarButton
        colorMode={deviceShadow.appearance}
        image={RunImage}
        darkImage={RunImageDark}
        text="RUN"
        isActive={deviceShadow.current_machine_state === 'RUN'}
        onClick={() => {
          if (!deviceShadow.lock_system) {
            setActiveView("RUN")
          }
        }}
        disabled={!initialDeviceReadingDone}
      />
      <SidebarButton
        colorMode={deviceShadow.appearance}
        image={StandbyImage}
        darkImage={StandbyImageDark}
        text="STANDBY"
        isActive={deviceShadow.current_machine_state === 'STANDBY'}
        onClick={() => {
          if (!deviceShadow.lock_system) {
            setActiveView("STANDBY");
          }
        }}
        disabled={!initialDeviceReadingDone}
      />
      <SidebarButton
        colorMode={deviceShadow.appearance}
        image={OffModeImage}
        darkImage={OffModeImageDark}
        text="OFF"
        isActive={deviceShadow.current_machine_state === 'OFF'}
        onClick={() => {
          if (!deviceShadow.lock_system) {
            setActiveView("OFF");
          }
        }}
        disabled={!initialDeviceReadingDone}
      />
    </Stack>
  );
}

// the style rules for this can be found at the theme level, with the definition for the custom variant "sidebar"
function SidebarButton({
  darkImage,
  image,
  colorMode,
  text,
  isActive,
  onClick,
  disabled
}: {
  colorMode: string;
  image: string;
  darkImage: string;
  text: string;
  isActive: boolean;
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <Button
      disabled={disabled}
      sx={{
        color: "primary.contrastText",
        display: "block",
        margin: 'auto'
      }}
      onClick={onClick}
    >
      <img
        src={colorMode === "LIGHT" ? image : darkImage}
        alt={text}
        style={{
          display: "block",
          margin: "auto",
          filter: isActive ? "invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)" : ""
        }}
      />
      {text}
    </Button>
  );
}
