import { Stack } from "@mui/material";
import { ReactNode } from "react";
import { SideBarContent } from "./sidebarContent";
import { ViewType } from "./viewType";

export function MainViewLandscapeLayout({
  children,
  setActiveView,
}: {
  children: ReactNode;
  setActiveView: (view: ViewType) => void;
}) {
  return (
    <Stack sx={{ display: "flex" }} direction='row'>
      <SideBarContent setActiveView={setActiveView} />
      {children}
    </Stack>
  );
}

