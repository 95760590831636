import { useCallback, useContext, useState } from "react";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import { HeaderText } from "./common/headerText";
import { MenuText } from "./common/menuText";
import { AlarmsMenu } from "./menus/alarmsMenu";
import { DisplayMenu } from "./menus/displayMenu";
import { ScheduleMenu } from "./menus/scheduleMenu";
import { TimeZoneMenu } from "./menus/timeZoneMenu";
import { UnitsMenu } from "./menus/unitsMenu";
import Close from './images/close/close.svg';
import CloseLight from './images/close/close-light.svg';
import { LockMenu } from "./menus/lockMenu";
import { AltitudeContext } from "./mainView/altitudeContext";
import { TextField } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";


type SubmenuType =
  | "schedule"
  | "display"
  | "time zone"
  | "units"
  | "alarms"
  | "lock"
  | null;


const MenuItem = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
}));


export function SettingsMenu({ onClose }: { onClose: () => void }) {
  const [activeSubmenu, setActiveSubmenu] = useState<SubmenuType>(null);
  const closeSubmenu = useCallback(() => setActiveSubmenu(null), []);
  const { deviceShadow, publishDeviceShadow, initialDeviceReadingDone } = useContext(AltitudeContext);
  const [pin, setPin] = useState('');
  const checkPin = () => {
    if (pin === deviceShadow.lock_settings_pin) {
      publishDeviceShadow({
        ...deviceShadow,
        lock_settings: false
      });
    } else {
      onClose();
    }
  }
  const { signOut } = useAuthenticator((context) => [context.user]);

  return (
    <>
      {
        deviceShadow.lock_settings && (
          <Drawer
            open
            anchor="right"
            onClose={onClose}
            sx={{ "& .MuiPaper-root": { width: "100%", padding: '38px 32px' } }}
          >
            <Grid container>
              <Grid item xs={6}>
                <HeaderText>Enter Settings Lock Pin</HeaderText>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Button onClick={onClose}>
                  <img src={deviceShadow.appearance === 'LIGHT' ? CloseLight : CloseLight} alt='Close' />
                </Button>
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="space-evenly" sx={{ mt: "2rem" }}>
              <TextField type="number" label="Enter Pin" value={pin} onChange={(e) => setPin(e.target.value)} />
              <Button
                variant="contained"
                color="primary"
                sx={{ fontWeight: 700 }}
                onClick={checkPin}
              >
                Accept
              </Button>
            </Stack>
          </Drawer>
        )
      }
      {!deviceShadow.lock_settings && !activeSubmenu && (
        <Drawer
          open
          anchor="right"
          onClose={onClose}
          sx={{ "& .MuiPaper-root": { width: "100%", padding: '38px 32px' } }}
        >
          <Grid container>
            <Grid item xs={6}>
              <HeaderText>SETTINGS</HeaderText>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Button onClick={onClose}>
                <img src={deviceShadow.appearance === 'LIGHT' ? Close : CloseLight} alt='Close' />
              </Button>
            </Grid>
          </Grid>
          <Stack>
            {
              initialDeviceReadingDone &&
              <>
                <MenuItem onClick={() => setActiveSubmenu("schedule")}>
                  <MenuText>Schedule</MenuText>
                </MenuItem>
                <MenuItem onClick={() => setActiveSubmenu("display")}>
                  <MenuText>Display</MenuText>
                </MenuItem>
                <MenuItem onClick={() => setActiveSubmenu("lock")}>
                  <MenuText>Lock</MenuText>
                </MenuItem>
              </>
            }

            <MenuItem onClick={signOut}>
              <MenuText>Log Out</MenuText>
            </MenuItem>
          </Stack>
        </Drawer>
      )}
      {activeSubmenu === "schedule" && <ScheduleMenu onClose={closeSubmenu} />}
      {activeSubmenu === "display" && <DisplayMenu onClose={closeSubmenu} />}
      {activeSubmenu === "time zone" && <TimeZoneMenu onClose={closeSubmenu} />}
      {activeSubmenu === "units" && <UnitsMenu onClose={closeSubmenu} />}
      {activeSubmenu === "alarms" && <AlarmsMenu onClose={closeSubmenu} />}
      {activeSubmenu === "lock" && <LockMenu onClose={closeSubmenu} onLock={onClose} />}
    </>
  );
}
