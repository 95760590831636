import { useContext, useState } from 'react';

// import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { HeaderText } from "../common/headerText";
import { ActSwitch } from '../common/actSwitch';
// import HelpIcon from '../images/help/help-icon.svg';
import { AltitudeContext } from '../mainView/altitudeContext';

import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { setOptions } from '@mobiscroll/react';
import { ScheduleCalendar } from './ScheduleCalendar';
import { HelpOverlay } from '../mainView/helpOverlay';

//TODO change help image for scheduler
import HelpOverlayImg from "../images/help/help-overlay.png";

setOptions({
  theme: 'ios',
  themeVariant: 'light'
});


export function ScheduleMenu({ onClose }) {
  const { deviceShadow, publishDeviceShadow, setDeviceShadow } = useContext(AltitudeContext);
  const [showHelpOverlay, setShowHelpOverlay] = useState(false);
  const setScheduleShadow = (e) => {
    const newShadow = {
      ...deviceShadow,
      enable_schedule: e.target.checked,
    };
    setDeviceShadow(newShadow);
    publishDeviceShadow(newShadow);
  };


  return (
    <Drawer
      open
      anchor="right"
      onClose={onClose}
      sx={{ "& .MuiPaper-root": { width: "100%", padding: "38px 8px" } }}
    >
      {
        showHelpOverlay ?
          <HelpOverlay img={HelpOverlayImg} open={showHelpOverlay} onClick={() => setShowHelpOverlay(false)} /> :
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={onClose}>
                <ChevronLeftIcon sx={{ fontSize: '50px' }} />
              </IconButton>
              <HeaderText>Schedule Menu</HeaderText>
              <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ width: "30%", mb: "17.5px", ml: "auto" }}>
                <FormControlLabel control={<ActSwitch checked={deviceShadow.enable_schedule}
                  onChange={(e) => setScheduleShadow(e)} />}
                  label={deviceShadow.enable_schedule ? 'Schedule On' : 'Schedule Off'} />
              </Stack>
              {/* <Button onClick={() => setShowHelpOverlay(true)} style={{ marginLeft: 'auto' }}>
                <img src={HelpIcon} alt="Help" height="32px" width="32px" />
              </Button> */}
            </Stack>
            <Divider />
            <Stack sx={{ mt: "1rem" }}>
              {
                deviceShadow.enable_schedule &&
                <ScheduleCalendar />
              }
            </Stack>
          </>
      }
    </Drawer>
  );
}
