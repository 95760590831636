import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Button, Eventcalendar, Popup } from '@mobiscroll/react';
import { AltitudeContext } from '../mainView/altitudeContext';

import './scheduleMenu.scss';

export const ScheduleCalendar = () => {

  const { deviceShadow, publishDeviceShadow, setDeviceShadow } = useContext(AltitudeContext);
  const [anchor, setAnchor] = useState(null);
  const [events, setEvents] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [clickedEvent, setClickedEvent] = useState(null);

  const getDateByIndex = (index) => {
    //whoever implemented the backend thinks 0 == monday Lol
    let dateIdx = index + 1;
    if (dateIdx === 7) {
      dateIdx = 0;
    }
    const offset = new Date().getDay() - dateIdx;
    const d = new Date();
    d.setDate(d.getDate() - offset);
    return d
  }

  const onEventClick = useCallback((args) => {
    const event = args.event;
    if (event.title !== 'RUN') return;
    setAnchor(args.domEvent.target)
    setOpen(true);
    setClickedEvent(args.event);
  }, []);

  const eventToShadow = (event, mode) => {
    const startDate = event.start;
    const stopDate = event.end;
    const startDotw = startDate.getDay();
    const stopDotw = stopDate.getDay();
    // to get military time, use GB locale
    const startTime = startDate.toLocaleTimeString('en-GB');
    const stopTime = stopDate.toLocaleTimeString('en-GB');
    return {
      mode,
      start_dotw: startDotw === 0 ? 6 : startDotw - 1,
      stop_dotw: stopDotw === 0 ? 6 : stopDotw - 1,
      start_time: startTime,
      stop_time: stopTime,
      id: uuidv4()
    };
  };
  const getEventStandByBufferShadow = (event) => {
    const eventEnd = event.end;
    // start buffer from 1 second of end for 10 minutes
    const newStart = eventEnd.getTime() + 1000;
    const startTimeDate = new Date(newStart);
    // ten minutes duration including the 1 sec after
    const stopTimeDate = new Date(newStart + 10000 * 60 - 1000);
    return {
      mode: 1,
      start_dotw: startTimeDate.getDay() === 0 ? 6 : startTimeDate.getDay() - 1,
      stop_dotw: stopTimeDate.getDay() === 0 ? 6 : stopTimeDate.getDay() - 1,
      start_time: startTimeDate.toLocaleTimeString('en-GB'),
      stop_time: stopTimeDate.toLocaleTimeString('en-GB'),
      id: uuidv4()
    }
  };

  const addRunEvent = (newEvent) => {
    // 1 == standby, 3 == run
    const shadowEvent = eventToShadow(newEvent, 3);
    const bufferEvent = getEventStandByBufferShadow(newEvent);
    // keep track of all overlapping dates to filter out
    const overlapIds = [];
    for (let i = 0; i < events.length; i++) {
      const e = events[i];
      if (e.title.includes('RUN') && newEvent.end >= e.start && newEvent.start <= e.end) {
        overlapIds.push(e.id);
        // standby must be after run
        overlapIds.push(events[i + 1].id);
      }
    }
    const schedule = [...deviceShadow.schedule, shadowEvent, bufferEvent];
    const newShadow = {
      ...deviceShadow,
      schedule: schedule.filter((s) => !overlapIds.includes(s.id))
    };
    setDeviceShadow(newShadow);
    publishDeviceShadow(newShadow)
  };

  const onEventCreated = (args) => {
    const event = args.event;
    addRunEvent(event);
  };

  const copyEventToNextDay = () => {
    const toCopyIdx = events.map((evt) => evt.id).indexOf(clickedEvent.id);
    const toCopy = events[toCopyIdx];
    const start = new Date(toCopy.start);
    start.setDate(toCopy.start.getDate() + 1);
    const end = new Date(toCopy.end);
    end.setDate(toCopy.end.getDate() + 1);
    const newEvent = {
      ...toCopy,
      id: uuidv4(),
      start,
      end,
    };
    addRunEvent(newEvent);
    setOpen(false);
  };

  const onDeleteClicked = () => {
    deleteEvent(clickedEvent.id);
  };

  const deleteEvent = (toDeleteId) => {
    const toDeleteIdx = events.map((evt) => evt.id).indexOf(toDeleteId);
    if (toDeleteIdx < 0) {
      return;
    }
    const toDelete = events[toDeleteIdx];
    if (toDelete.title === 'STANDBY' && events[toDeleteIdx - 1]?.title === 'RUN') {
      alert('Cannot delete required standby event after run state');
      return;
    }

    setOpen(false);
    const scheduleDeleteIdx = deviceShadow.schedule.map((s) => s.id).indexOf(toDeleteId);
    const scheduleStandbyIdx = scheduleDeleteIdx + 1;
    const associatedRunEventId = `${clickedEvent.id}-2`;
    const associatedRunEventIdx = deviceShadow.schedule.map((s) => s.id).indexOf(associatedRunEventId);
    const newSchedule = [...deviceShadow.schedule].filter(
      (_, idx) => idx !== scheduleDeleteIdx && idx !== scheduleStandbyIdx && (associatedRunEventIdx < 0 || idx !== associatedRunEventIdx)
    )

    const newShadow = {
      ...deviceShadow,
      schedule: newSchedule,
    };
    setDeviceShadow(newShadow);
    publishDeviceShadow(newShadow);
  };

  const view = useMemo(() => {
    return {
      schedule: { type: 'week' }
    };
  }, []);

  useEffect(() => {
    // only show run (3) or standby (1)
    const currentEvents = deviceShadow.schedule.filter(
      (evt) => evt.mode === 3 || evt.mode === 1
    ).flatMap(
      (evt) => {
        const startDate = getDateByIndex(evt.start_dotw);
        const startTimeParts = evt.start_time.split(":");
        startDate.setHours(...startTimeParts);
        const stopDate = getDateByIndex(evt.stop_dotw);
        const trueStartIdx = evt.start_dotw + 1 === 7 ? 0 : evt.start_dotw + 1;
        const trueEndIdx = evt.stop_dotw + 1 === 7 ? 0 : evt.stop_dotw + 1;
        const stopTimeParts = evt.stop_time.split(":");
        stopDate.setHours(...stopTimeParts);

        if (trueEndIdx < trueStartIdx) {
          // make two different events one for the start date until midnight, and another for end date until end time
          const startDateMidnight = new Date(startDate.getTime());
          // 24 0 0 0 = midght the following night
          startDateMidnight.setHours(24, 0, 0, 0);
          const startDateEvt = {
            start: startDate,
            end: startDateMidnight,
            title: evt.mode === 1 ? 'STANDBY' : 'RUN',
            id: evt.id,
            color: evt.mode === 1 ? 'red' : 'blue'
          };
          const endDateMidnight = new Date(stopDate.getTime());
          // 0 0 0 0 midnight morning before
          endDateMidnight.setHours(0, 0, 0, 0);
          const endDateEvt = {
            start: endDateMidnight,
            end: stopDate,
            title: evt.mode === 1 ? 'STANDBY' : 'RUN (from previous)',
            id: `${evt.id}-2`,
            color: evt.mode === 1 ? 'red' : 'blue'
          }
          return [startDateEvt, endDateEvt];
        } else {
          const calendarEvt = {
            start: startDate,
            end: stopDate,
            title: evt.mode === 1 ? 'STANDBY' : 'RUN',
            id: evt.id,
            color: evt.mode === 1 ? 'red' : 'blue'
          }
          return [calendarEvt];
        }
      }
    );
    setEvents(currentEvents);
  }, [deviceShadow.schedule]);

  return (
    <>
      <Eventcalendar
        theme={deviceShadow.appearance === 'LIGHT' ? 'ios' : 'material-dark'}
        clickToCreate={true}
        className={deviceShadow.appearance === 'LIGHT' ? '' : "act-calendar"}
        dragToCreate={true}
        dragToMove={false}
        dragToResize={false}
        eventDelete={true}
        data={events}
        view={view}
        onEventClick={onEventClick}
        dataTimezone={deviceShadow.time_zone}
        onEventCreated={onEventCreated}
        height={"65vh"}
      />
      <Popup
        display="anchored"
        isOpen={isOpen}
        anchor={anchor}
        onClose={() => setOpen(false)}
      >

        <Button
          className="mbsc-button-block"
          color="primary"
          variant="outline"
          onClick={copyEventToNextDay}
        >
          Copy to next day
        </Button>
        <Button
          className="mbsc-button-block"
          color="danger"
          variant="outline"
          onClick={onDeleteClicked}
        >
          Delete
        </Button>
      </Popup>
    </>
  )
}