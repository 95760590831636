import React, { useEffect, useState } from 'react';
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useContext } from "react";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Divider, PaletteMode } from "@mui/material";
import { HeaderText } from "../common/headerText";
import { MenuText } from "../common/menuText";
import { AltitudeContext } from "../mainView/altitudeContext";
import { ActSwitch } from '../common/actSwitch';


export function DisplayMenu({ onClose }: { onClose: () => void }) {
  const { deviceShadow, setDeviceShadow, publishDeviceShadow } = useContext(AltitudeContext);
  const [colorMode, setColorMode] = useState(deviceShadow.appearance.toLowerCase());
  const getSwitchLabel = (field: keyof typeof deviceShadow): string => {
    return deviceShadow[field] ? 'On' : 'Off'
  };

  const setShadow = (e: React.ChangeEvent<HTMLInputElement>, field: keyof typeof deviceShadow,) => {
    const newShadow = {
      ...deviceShadow,
      [field]: e.target.checked
    };
    setDeviceShadow(newShadow);
    publishDeviceShadow(newShadow);
  };

  useEffect(() => {
    const newShadow = {
      ...deviceShadow,
      appearance: colorMode === 'dark' ? 'DARK' : 'LIGHT'
    }
    setDeviceShadow(newShadow);
    publishDeviceShadow(newShadow);
  }, [colorMode]);



  return (
    <Drawer
      open
      anchor="right"
      onClose={onClose}
      sx={{ "& .MuiPaper-root": { width: "100%", padding: "38px 32px" } }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton onClick={onClose}>
          <ChevronLeftIcon sx={{ fontSize: '50px' }} />
        </IconButton>
        <HeaderText>Display menu</HeaderText>
      </Stack>
      <Divider />
      <Stack sx={{ mt: "2rem" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: "50%" }}>
          <MenuText>Appearance</MenuText>
          <FormControl>
            <RadioGroup
              row
              name="color mode selection"
              value={colorMode}
              onChange={(e) => setColorMode(e.target.value as PaletteMode)}
            >
              <FormControlLabel
                value="light"
                control={<Radio />}
                label="Light"
              />
              <FormControlLabel value="dark" control={<Radio />} label="Dark" />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Stack>
      <Stack sx={{ mt: "2rem" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: "50%" }}>
          <MenuText>Display CO2, O2, Pa</MenuText>
          <FormControlLabel control={
            <ActSwitch checked={deviceShadow.show_stats}
              onChange={(e) => setShadow(e, 'show_stats')} />}
            label={getSwitchLabel('show_stats')} />
        </Stack>
      </Stack>
      {/* <Stack sx={{ mt: "2rem" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: "50%" }}>
          <MenuText>Display Altitude Graph</MenuText>
          <FormControlLabel control={<ActSwitch checked={deviceShadow.show_alt_graph}

            onChange={(e) => setShadow(e, 'show_alt_graph')} />}
            label={getSwitchLabel('show_alt_graph')} />
        </Stack>
      </Stack> */}
      {/* <Stack sx={{ mt: "2rem" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: "50%" }}>
          <MenuText>Display CO2 Graph</MenuText>
          <FormControlLabel control={<ActSwitch checked={deviceShadow.show_co2_graph}
            onChange={(e) => setShadow(e, 'show_co2_graph')} />}
            label={getSwitchLabel('show_co2_graph')} />
        </Stack>
      </Stack> */}
    </Drawer>
  );
}
