import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export function AlarmsMenu({ onClose }: { onClose: () => void }) {
  return (
    <Drawer
      open
      anchor="right"
      onClose={onClose}
      sx={{ "& .MuiPaper-root": { width: "100%" } }}
    >
      <Stack direction="row" spacing={1}>
        <IconButton onClick={onClose}>
          <ChevronLeftIcon />
        </IconButton>
        <Box>Alarms menu</Box>
      </Stack>
    </Drawer>
  );
}
