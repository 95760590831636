import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import { ReactNode } from "react";
import { SideBarContent } from "./sidebarContent";
import { ViewType } from "./viewType";

export function MainViewPortraitLayout({
  children,
  setActiveView,
}: {
  children: ReactNode;
  setActiveView: (view: ViewType) => void;
}) {
  return (
    <Grid container sx={{ p: 2 }}>
      <Grid item xs={12}>
        {children}
      </Grid>
      <SideBarContainerPortrait>
        <SideBarContent setActiveView={setActiveView} />
      </SideBarContainerPortrait>
    </Grid>
  );
}

function SideBarContainerPortrait({ children }: { children: ReactNode }) {
  return (
    <Drawer
      variant="permanent"
      anchor="bottom"
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#1D3444",
          p: 2,
          // width: 134,
        },
      }}
    >
      {children}
    </Drawer>
  );
}
