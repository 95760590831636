import { useContext } from 'react';
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { AltitudeContext } from './altitudeContext';

import RunImageSelected from "../images/run/mode-run-selected.svg";


export function OffView({ activateRunMode }: { activateRunMode: () => void }) {
  const { initialDeviceReadingDone, shadowGetResponded } = useContext(AltitudeContext);
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        variant="h1"
        sx={{ fontSize: { xs: "27px" }, fontWeight: 600 }}
      >
        ALTITUDE CONTROL SYSTEM
      </Typography>
      <Typography
        variant="h1"
        component="h2"
        sx={{ fontSize: { xs: "145px" }, opacity: 0.5 }}
      >
        OFF
      </Typography>
      <Typography variant="body1">
        Adjust the effective altitude in this room
      </Typography>
      <Button variant="contained" sx={{ backgroundColor: '#1D3444', mt: '18px' }} onClick={activateRunMode}
        disabled={!initialDeviceReadingDone || !shadowGetResponded}>
        <img
          src={RunImageSelected}
          alt={'Run'}
          style={{
            marginRight: '12px'
          }}
        />
        Run
      </Button>
    </Box>
  );
}
