import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import { ReactNode, useContext } from "react";
import { AltitudeContext } from "./altitudeContext";

import { HeaderText } from "../common/headerText";
import { Divider } from "@mui/material";

export function StandbyDisplay() {
  const {
    deviceSettings,
    deviceShadow,
  } = useContext(AltitudeContext);
  return (
    <>
      <Stack sx={{ pt: 2, height: '100%' }} justifyContent="center" alignItems="center">
        <HeaderText sx={{ mb: '8px' }}>Current altitude</HeaderText>
        <Stack direction="row">
          <NumberDisplay color="#FF6900">{deviceSettings.effective_altitude}</NumberDisplay>
          <UnitDisplay sx={{ mt: "auto" }}>ft</UnitDisplay>
        </Stack>
      </Stack>
      {
        deviceShadow.show_stats &&
        <>
          <Divider />
          <Stack sx={{ pt: '8px', pb: '8px', ml: '2rem' }} direction="row">
            <HeaderText sx={{ mr: '55px', textTransform: 'unset' }}>CO2 {deviceSettings.co2_ppm} (ppm)</HeaderText>
            <HeaderText sx={{ mr: '55px', textTransform: 'unset' }}>O2 {((deviceSettings.o2_a_ppm + deviceSettings.o2_b_ppm) * 0.5 / 10000).toFixed(1)} (%)</HeaderText>
            <HeaderText sx={{ textTransform: 'unset' }}>Pa {deviceSettings.pressure_mb} (mb)</HeaderText>
          </Stack>
        </>
      }
    </>
  );
}


function NumberDisplay({
  children,
  color,
  editMode,
  showSmallerFont,
}: {
  children: ReactNode;
  color: string;
  editMode?: boolean;
  showSmallerFont?: boolean;
}) {

  let xsFontSize = '200px';
  // smaller cuz we want it to fit in same row
  let smFontSize = '100px';
  let mdFontSize = '145px;'

  if (editMode) {
    xsFontSize = '210px';
    smFontSize = '135px';
    mdFontSize = '290px';
  }

  if (showSmallerFont) {
    xsFontSize = '75px';
    smFontSize = '50px';
    mdFontSize = '70px';
  }

  return (
    <Box
      sx={{
        fontSize: { xs: xsFontSize, sm: smFontSize, md: mdFontSize },
        fontWeight: 300,
        color: color,
        display: "inline",
        lineHeight: 0.8,
      }}
    >
      {children}
    </Box>
  );
}

const UnitDisplay = styled("span")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "28px",
  },
  fontWeight: 600,
  textTransform: "uppercase",
}));
