import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { SnackbarProvider } from "notistack";
import React, { ReactNode, useContext } from "react";
import { MainView } from "./mainView/mainView";
import { SettingsContextProvider } from "./settingsContext";
import { getBaseTheme } from "./theme/theme";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import { AltitudeContext, AltitudeContextProvider } from "./mainView/altitudeContext";


const settings = {
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

Amplify.configure(settings);

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: process.env.REACT_APP_AWS_REGION,
    aws_pubsub_endpoint: process.env.REACT_APP_AWS_PUBSUB_ENDPOINT,
  })
);

function App() {
  return (
    <SnackbarProvider maxSnack={5}>
      <SettingsContextProvider>
        <AltitudeContextProvider>
          <ColorModeAwareThemeProvider>
            <CssBaseline />
            <MainView />
          </ColorModeAwareThemeProvider>
        </AltitudeContextProvider>
      </SettingsContextProvider>
    </SnackbarProvider>
  );
}

function ColorModeAwareThemeProvider({ children }: { children: ReactNode }) {
  const { deviceShadow } = useContext(AltitudeContext);
  const colorMode = deviceShadow.appearance === 'LIGHT' ? 'light' : 'dark';
  const theme = React.useMemo(() => getBaseTheme(colorMode), [colorMode]);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default withAuthenticator(App, {
  hideSignUp: true,
  // Show only certain components
  // authenticatorComponents: [MyComponents],
  // display federation/social provider buttons
  // federated: { google_client_id: "658201719662-k0k34lh6hh445q4kaesl1hv3543o7lse.apps.googleusercontent.com" },
  // customize the UI/styling
  // theme: { myCustomTheme }
});
