import React, { ReactNode, useContext, useState, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Slider, { SliderThumb } from "@mui/material/Slider";
import styled from "@mui/material/styles/styled";
import UpArrow from "../images/up-arrow/up-arrow.svg";
import UpArrowDark from "../images/up-arrow/up-arrow-dark.svg";
import UpArrowDisabled from "../images/up-arrow/up-arrow-disabled.svg";
import UpArrowDisabledDark from "../images/up-arrow/up-arrow-disabled-dark.svg";
import DownArrow from "../images/down-arrow/down-arrow.svg";
import DownArrowDark from "../images/down-arrow/down-arrow-dark.svg";
import DownArrowDisabled from "../images/down-arrow/down-arrow-disabled.svg";
import DownArrowDarkDisabled from "../images/down-arrow/down-arrow-disabled-dark.svg";
import AltitudeCheck from "../images/altitude/altitude-check.svg";
import AltitudeLowering from '../images/altitude/altitude-lowering.svg';
import AltitudeRaising from '../images/altitude/altitude-raising.svg';
import CheckLight from '../images/check/altitude-check.svg';
import CheckDark from '../images/check/altitude-check-dark.svg';
import Plus from "../images/plus/plus.svg";
import Minus from "../images/minus/minus.svg";
import { AltitudeContext } from "./altitudeContext";
import { Divider } from "@mui/material";

import { HeaderText } from "../common/headerText";

interface ActThumbComponentProps extends React.HTMLAttributes<unknown> { }


const ActSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    backgroundColor: 'red',
    color: 'red',
    '& .act-thumb': {
    },
  },
}));

function ActThumbComponent(props: ActThumbComponentProps) {
  const { children, ...other } = props;
  const { deviceShadow, deviceSettings } = useContext(AltitudeContext);

  const getAltitudeArrowIcon = useCallback(() => {
    const withinRange = 500;
    if (Math.abs(deviceShadow.setpoint - deviceSettings.effective_altitude) <= withinRange) {
      return AltitudeCheck
    } else if (deviceSettings.effective_altitude > deviceShadow.setpoint) {
      return AltitudeLowering;
    } else {
      return AltitudeRaising;
    }
  }, [deviceSettings.effective_altitude, deviceShadow.setpoint]);


  return (
    <SliderThumb {...other}>
      {children}
      <img src={getAltitudeArrowIcon()} alt="Altitude Set" />
    </SliderThumb>
  );
}


export function AltitudeDisplay() {
  const {
    deviceSettings,
    deviceShadow,
    decrementTargetAltitude,
    incrementTargetAltitude,
    publishDeviceShadow,
  } = useContext(AltitudeContext);
  const [editModeIsActive, setEditModeIsActive] = useState(false);
  const minSliderMark = Math.round(
    Math.min(deviceSettings.effective_altitude, deviceShadow.setpoint) * 0.8
  ) || 0;
  const maxSliderMark = deviceSettings.local_elevation || 0;
  const marks = [
    {
      value: minSliderMark,
      label: `${minSliderMark}ft`,
    },
    {
      value: maxSliderMark,
      label: `${maxSliderMark}ft`,
    },
  ];

  const onAltitudeChanged = () => {
    publishDeviceShadow({
      ...deviceShadow
    });
    setEditModeIsActive(false);
  }
  const showAltitudeReached = useMemo(() => {
    // same as tablet, within 500feet we're good
    const threshold = 1000;
    return deviceShadow.residential_mode && Math.abs(deviceSettings.effective_altitude - deviceShadow.setpoint) <= threshold;
  }, [deviceSettings.effective_altitude, deviceShadow.setpoint, deviceShadow.residential_mode])

  return (
    <>
      <Grid container rowSpacing={4} sx={{ pt: 2, height: '100%' }} alignItems='center' direction='row' justifyContent='center'>
        {
          showAltitudeReached ?
            <Stack direction='row' justifyContent='space-evenly' alignItems='center' sx={{ width: '20%' }}>
              <img src={deviceShadow.appearance === "LIGHT" ? CheckLight : CheckDark} alt='Oxygenated' height={50} width={50} />
              <h2>Ahhhhh... Oxygen</h2>
            </Stack>
            :
            <>

              <Grid item xs={1} sx={{ height: "80%", pt: '24px !important' }}>
                <ActSlider //https://mui.com/material-ui/react-slider/
                  // just random values to silence errors
                  value={deviceSettings.effective_altitude || 2000}
                  orientation="vertical"
                  getAriaValueText={(v) => `${v}ft`}
                  marks={marks}
                  max={maxSliderMark || 0}
                  min={minSliderMark || 0}
                  slots={{ thumb: ActThumbComponent }}
                  sx={{
                    "& .MuiSlider-track": {
                      color: "#FF6900",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={editModeIsActive ? 3 : 5} justifyContent='center' sx={{ mr: '1rem', pt: '0 !important' }}>
                <Stack>
                  {!editModeIsActive &&
                    <HeaderText sx={{ mb: '8px' }}>Current altitude</HeaderText>
                  }
                  <NumberDisplay color="#FF6900" showSmallerFont={editModeIsActive}>{deviceSettings.effective_altitude}</NumberDisplay>
                  <UnitDisplay>ft</UnitDisplay>
                </Stack>
              </Grid>
              <Divider sx={{ mt: '35px' }} flexItem orientation="vertical" />
              <Grid item xs={12} sm={editModeIsActive ? 7 : 5} justifyContent='center' sx={{ pl: '1rem', pt: '0 !important' }}>
                <Stack direction="row" sx={{ 'alignItems': 'center', width: '100%' }}>
                  <Stack direction='column'>
                    <HeaderText sx={{ mb: '8px' }}>
                      {
                        !editModeIsActive && 'Altitude set to'
                      }
                    </HeaderText>
                    <Stack>
                      <NumberDisplay color="#00BBFF" editMode={editModeIsActive}>
                        {editModeIsActive ? (
                          <EditableNumberDisplay
                            num={deviceShadow.setpoint}
                            decrement={decrementTargetAltitude}
                            increment={incrementTargetAltitude}
                          />
                        ) : (
                          deviceShadow.setpoint
                        )}
                      </NumberDisplay>
                      <UnitDisplay>ft</UnitDisplay>
                    </Stack>
                  </Stack>
                  <Stack sx={{ ml: 'auto' }}>
                    {
                      deviceShadow.lock_system ?
                        <>
                          <Button>
                            <img
                              src={deviceShadow.appearance === "LIGHT" ? UpArrowDisabled : UpArrowDisabledDark}
                              alt="up-arrow"
                            />
                          </Button>
                          <Button>
                            <img
                              src={deviceShadow.appearance === "LIGHT" ? DownArrowDisabled : DownArrowDarkDisabled}
                              alt="down-arrow"
                            />
                          </Button>
                        </> :
                        <>
                          <Button onClick={() => editModeIsActive ? incrementTargetAltitude() : setEditModeIsActive(true)}>
                            <img
                              src={deviceShadow.appearance === "LIGHT" ? UpArrow : UpArrowDark}
                              alt="up-arrow"
                            />
                          </Button>
                          <Button onClick={() => editModeIsActive ? decrementTargetAltitude() : setEditModeIsActive(true)}>
                            <img
                              src={deviceShadow.appearance === "LIGHT" ? DownArrow : DownArrowDark}
                              alt="down-arrow"
                            />
                          </Button>
                          {editModeIsActive && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                onAltitudeChanged();
                                setEditModeIsActive(false);
                              }}
                            >
                              Done
                            </Button>
                          )}
                        </>
                    }
                  </Stack>
                </Stack>
              </Grid>


            </>
        }
      </Grid >
      <Divider />
      {
        deviceShadow.show_stats &&
        <Stack sx={{ pt: '8px', pb: '8px', ml: '2rem' }} direction="row">
          <HeaderText sx={{ mr: '55px', textTransform: 'unset' }}>CO2 {deviceSettings.co2_ppm} (ppm)</HeaderText>
          <HeaderText sx={{ mr: '55px', textTransform: 'unset' }}>O2 {((deviceSettings.o2_a_ppm + deviceSettings.o2_b_ppm) * 0.5 / 10000).toFixed(1)} (%)</HeaderText>
          <HeaderText sx={{ textTransform: 'unset' }}>Pa {deviceSettings.pressure_mb} (mb)</HeaderText>
        </Stack>
      }
    </>
  );
}


function NumberDisplay({
  children,
  color,
  editMode,
  showSmallerFont,
}: {
  children: ReactNode;
  color: string;
  editMode?: boolean;
  showSmallerFont?: boolean;
}) {

  let xsFontSize = '200px';
  // smaller cuz we want it to fit in same row
  let smFontSize = '100px';
  let mdFontSize = '145px;'

  if (editMode) {
    xsFontSize = '210px';
    smFontSize = '135px';
    mdFontSize = '290px';
  }

  if (showSmallerFont) {
    xsFontSize = '75px';
    smFontSize = '50px';
    mdFontSize = '70px';
  }

  return (
    <Box
      sx={{
        fontSize: { xs: xsFontSize, sm: smFontSize, md: mdFontSize },
        fontWeight: 300,
        color: color,
        display: "inline",
        lineHeight: 0.8,
      }}
    >
      {children}
    </Box>
  );
}

function EditableNumberDisplay({
  num,
  increment,
  decrement,
}: {
  num: number;
  increment: (num?: number) => void;
  decrement: (num?: number) => void;
}) {
  const digitChars = num.toString().split("");
  const withPlaces = digitChars
    .reverse()
    .map((d, i) => ({ digit: d, incrementValue: Math.pow(10, i) }))
    .reverse();
  return (
    <Stack direction="row">
      {withPlaces.map((d) => (
        <Stack direction="column" key={d.incrementValue}>
          <Button onClick={() => increment(d.incrementValue)}>
            <img src={Plus} alt="plus-button" style={{ width: "25px" }} />
          </Button>
          <span>{d.digit}</span>
          <Button onClick={() => decrement(d.incrementValue)}>
            <img src={Minus} alt="minus-button" style={{ width: "25px" }} />
          </Button>
        </Stack>
      ))}
    </Stack>
  );
}

const UnitDisplay = styled("span")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "28px",
  },
  fontWeight: 600,
  textTransform: "uppercase",
}));
