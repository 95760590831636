import styled from "@mui/material/styles/styled";


export const MenuText: any = styled("header")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "16px",
    },
    fontWeight: 700,
    lineHeight: 1,
}));
